<!-- Main container -->
<div class="md:container md:mx-auto">
  <!-- Hero section -->
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div class="sm:text-center lg:text-left">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block xl:inline">Sergio</span>
            </h1>
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block text-indigo-600 xl:inline">Navarro</span>
            </h1>
            <p
              class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Computer Science Engineering graduated from the University of the Basque Country.
            </p>
            <div class="mt-5 sm:mt-8 inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <div class="h-12 w-12 m-2 grow-medium">
                <a href="https://www.linkedin.com/in/sergio-navarro-b3253719b/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                  </svg>
                </a>
              </div>
              <div class="h-12 w-12 m-2 grow-medium">
                <a href="https://github.com/serNAVARRO7" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">

                    <path
                      d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="sm:h-96 md:h-96 lg:h-full w-full object-cover" style="object-fit: cover; object-position: 100% 10%;"
        src="../../assets/heroFilter2.jpeg" alt="hero">
    </div>
  </div>
  <!-- Education and expirence section -->
  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <!-- Education section -->
          <div class="relative">
            <div class="lg:text-center">
              <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Studies</h2>
            </div>
            <section class="text-gray-600 body-font">
              <div class="container px-5 py-5 mx-auto flex flex-wrap">
                <div class="flex flex-wrap w-full">
                  <div>
                    <div class="flex relative pb-12">
                      <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div
                        class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-600 inline-flex items-center justify-center text-white relative z-10">
                        <mat-icon aria-hidden="false">school
                        </mat-icon>
                      </div>
                      <div class="flex-grow pl-4">
                        <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          Bachelor Degree in Computer Engineering in Management and Information Systems
                        </h2>
                        <p class="leading-relaxed">University of the Basque Country</p>
                        <p class="leading-relaxed">Bachelor's Degree Extraordinary Award</p>
                        <p class="leading-relaxed">2016 - 2020</p>
                      </div>
                    </div>
                    <div class="flex relative pb-12">
                      <div
                        class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-600 inline-flex items-center justify-center text-white relative z-10">
                        <mat-icon aria-hidden="false">timeline
                        </mat-icon>
                      </div>
                      <div class="flex-grow pl-4">
                        <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          AI Saturdays Euskadi Donostia
                        </h2>
                        <p class="leading-relaxed">Machine learning itinerary</p>
                        <p class="leading-relaxed">2020</p>
                      </div>
                    </div>
                    <div class="flex relative">
                      <div
                        class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-600 inline-flex items-center justify-center text-white relative z-10">
                        <mat-icon aria-hidden="false">mode
                        </mat-icon>
                      </div>
                      <div class="flex-grow pl-4">
                        <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          General Certificate of Education
                        </h2>
                        <p class="leading-relaxed">Egibide Jesus Obrero</p>
                        <p class="leading-relaxed">2014 - 2016</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- Experience section -->
          <div class="relative">
            <div class="lg:text-center">
              <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Work experience
              </h2>
            </div>
            <section class="text-gray-600 body-font">
              <div class="container px-5 py-5 mx-auto flex flex-wrap">
                <div class="flex flex-wrap w-full">
                  <div>
                    <div>
                      <div class="flex relative pb-12">
                        <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                          <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div
                          class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-600 inline-flex items-center justify-center text-white relative z-10">
                          <mat-icon aria-hidden="false" aria-label="Example home icon">laptop
                          </mat-icon>
                        </div>
                        <div class="flex-grow pl-4">
                          <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                            Full Stack Developer - Lantek</h2>
                          <p class="leading-relaxed">Development of Lantek 360, the suite of cloud-based products for
                            cutting machines and Industry 4.0: Angular, .Net, SQL Server and Azure</p>
                          <p class="leading-relaxed">sep 2021 - present</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex relative pb-12">
                      <div
                        class="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-600 inline-flex items-center justify-center text-white relative z-10">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">keyboard
                        </mat-icon>
                      </div>
                      <div class="flex-grow pl-4">
                        <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          Full Stack Developer - Orvium</h2>
                        <p class="leading-relaxed">Edtech platform development:
                          Angular, NestJS, MongoDB y AWS</p>
                        <p class="leading-relaxed">feb 2020 - sep 2021</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <!-- Technologies section -->
  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Technologies</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          My Tech Stack
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Always ready to acquire new knowledge and face new challenges.
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/angular.png">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Angular</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Framework for building modern single-page client applications SPA.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/nestjs.svg">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">NestJS</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Framework for building efficient, scalable Node.js web applications.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/mongodb.svg">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">MongoDB</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              General purpose, document-based, distributed database built for modern applications and for
              the cloud era.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/nodejs.svg">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">NodeJS</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              JavaScript runtime built on Chrome's V8 JavaScript engine.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/aws.png">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">AWS</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Flexible, reliable, scalable and cost-effective cloud computing solutions.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                <img src="../../assets/git.png">
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Git</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Open source distributed version control system.
            </dd>
          </div>
        </dl>
      </div>
      <div class="py-10 lg:text-center lg:w-3/4 mx-auto">
        <p class="leading-relaxed">CI/CD, Docker, Kubernetes, Express, Ionic, Flask, Spring, MySQL, Kafka, Dapr,
          Protocols
          implementation (OAI-PMH), Unity game engine.</p>
      </div>
    </div>
  </div>
  <!-- Contact me section -->
  <section class="text-gray-600 body-font relative">
    <div class="container px-5 py-5 mx-auto">
      <div class="lg:text-center py-3">
        <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Contact</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Contact me
        </p>
      </div>
      <div class="lg:w-1/2 mx-auto">
        <div class="flex flex-wrap -m-2" [formGroup]="contactForm">
          <div class="p-2 w-full">
            <div class="relative">
              <label for="name" class="leading-7 text-sm text-gray-600">Subject</label>
              <input type="text" id="subject" name="subject" formControlName="subject"
                class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
          </div>
          <div class="p-2 w-full">
            <div class="relative">
              <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
              <textarea id="message" name="message" formControlName="message"
                class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
          </div>
          <div class="p-2 w-full">
            <button (click)="mailMe()"
              class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              Send
            </button>
            <p *ngIf="invalidForm" class="py-2 text-center leading-relaxed text-red">Hey listen! The form is
              not valid.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- Footer -->
<footer class="text-gray-600 body-font">
  <div class="container px-5 py-5 mx-auto flex items-center sm:flex-row flex-col">
    <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <span class="ml-3 text-xl">Sergio Navarro</span>
    </a>
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
      Web developed using Angular and Tailwind CSS © {{year}}
    </p>
    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a class="ml-3 text-gray-500" [routerLink]="['/es']">
        Versión Español
      </a>
      <a class="ml-3 text-gray-500 grow-medium" href="https://www.linkedin.com/in/sergio-navarro-b3253719b/"
        target="_blank">
        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
          class="w-5 h-5" viewBox="0 0 24 24">
          <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
          </path>
          <circle cx="4" cy="4" r="2" stroke="none"></circle>
        </svg>
      </a>
      <a class="ml-3 text-gray-500 grow-medium" href="https://github.com/serNAVARRO7 " target="_blank">
        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
          class="w-5 h-5" viewBox="0 0 24 24">
          <path
            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          <circle cx="4" cy="4" r="2" stroke="none"></circle>
        </svg>
      </a>
    </span>
  </div>
</footer>
